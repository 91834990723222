<template>
  <div
    v-if="typeof isCompleted === 'boolean'"
    class="badge rounded-pill badge-primary d-inline-flex align-items-center"
    :class="style"
  >
    <fa-icon v-if="isCompleted === true" :icon="['fas', 'check']"></fa-icon>
    <fa-icon v-if="isCompleted === false" :icon="['fas', 'times']"></fa-icon>
    <span class="ms-1">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'ExerciseStatus',
  props: {
    isCompleted: {
      type: Boolean,
    },
  },
  computed: {
    style() {
      const { isCompleted } = this;
      return isCompleted === true ? 'bg-primary' : 'bg-danger';
    },
    text() {
      const { isCompleted } = this;
      return isCompleted === true ? 'Completed' : 'Missed';
    },
  },
};
</script>
